var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var CaseCollectorService = /** @class */ (function (_super) {
    __extends(CaseCollectorService, _super);
    function CaseCollectorService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 查询登录人相关的委托方
     */
    CaseCollectorService.prototype.findPrincipal = function (loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.findPrincipal,
            loading: loading,
        });
    };
    /**
     * 在调案件查询
     */
    CaseCollectorService.prototype.findAllInCollectCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.findAllInCollectCase,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                cardNo: data.cardNo,
                phone: data.phone,
                caseNumber: data.caseNumber,
                employerName: data.employerName,
                address: data.address,
                delegationDateStart: data.delegationDate[0],
                delegationDateEnd: data.delegationDate[1],
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                overdueAmtTotalStart: data.overdueAmtTotal.min,
                overdueAmtTotalEnd: data.overdueAmtTotal.max,
                delegationMonth: data.delegationMonth,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                city: data.city,
                endCaseDateStart: data.endCaseDate[0],
                endCaseDateEnd: data.endCaseDate[1],
                retireCaseDays: data.retireCaseDays,
                stage: data.stage,
                comment: data.comment,
                caseType: data.caseType,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询委托方案件
     */
    CaseCollectorService.prototype.findAllCaseByPrincipal = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.findAllCaseByPrincipal,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 退案案件查询
     */
    CaseCollectorService.prototype.findAllRetireCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.findAllRetireCase,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                selfPhoneNo: data.selfPhoneNo,
                caseNumber: data.caseNumber,
                stage: data.stage,
                minOverdueAmtTotal: data.overdueAmtTotal[0],
                maxOverdueAmtTotal: data.overdueAmtTotal[1],
                employerName: data.employerName,
                address: data.address,
                minDelegationDate: data.delegationDate[0],
                maxDelegationDate: data.delegationDate[1],
                minOverduePeriods: data.overduePeriods[0],
                maxOverduePeriods: data.overduePeriods[1],
                minOverdueDays: data.overdueDays[0],
                maxOverdueDays: data.overdueDays[1],
                cardNo: data.cardNo,
                city: data.city,
                minEndCaseDate: data.endCaseDate[0],
                maxEndCaseDate: data.endCaseDate[1],
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询委托方下全部批次案件
     */
    CaseCollectorService.prototype.findAllBatchCase = function (batchNumber, principalId, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.findAllBatchCase,
            data: {
                batchNumber: batchNumber,
                principalId: principalId,
            },
            loading: loading,
        });
    };
    /**
     * 查询委托方下全部案件
     */
    CaseCollectorService.prototype.findAllCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.findAllCase,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                caseNumber: data.caseNumber,
                cardNo: data.cardNo,
                phone: data.phone,
                stage: data.stage,
                collectStatus: data.collectStatus,
                contactStatus: data.contactStatus,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal ? data.overdueAmtTotal.min : null,
                overdueAmtTotalEnd: data.overdueAmtTotal ? data.overdueAmtTotal.max : null,
                leftAmtStart: data.leftAmt ? data.leftAmt.min : null,
                leftAmtEnd: data.leftAmt ? data.leftAmt.max : null,
                payStatus: data.payStatus,
                isAssist: data.isAssist,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                comment: data.comment,
                collectorName: data.collectorName,
                departCode: data.departCode,
                overduePeriodsStart: data.overduePeriods ? data.overduePeriods.min : null,
                overduePeriodsEnd: data.overduePeriods ? data.overduePeriods.max : null,
                overdueDaysStart: data.overdueDays ? data.overdueDays.min : null,
                overdueDaysEnd: data.overdueDays ? data.overdueDays.max : null,
                telRecordCount: data.telRecordCount,
                city: data.city,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                caseFiller: data.caseFiller,
                retireCaseDays: data.retireCaseDays,
                caseType: data.caseType,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                phones: data.phones ? data.phones.split(",") : null,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 跟进案件ID获取案件
     */
    CaseCollectorService.prototype.getCaseInfoById = function (caseId, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.getCaseInfoById,
            data: {
                caseId: caseId,
            },
            loading: loading,
        });
    };
    /**
     * 获取客户账号卡号
     */
    CaseCollectorService.prototype.getCardInfoByIdCard = function (certificateNo, caseId, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.getCardInfoByIdCard,
            data: {
                certificateNo: certificateNo,
                caseId: caseId,
            },
            loading: loading,
        });
    };
    /**
     * 查询案件记事本内容
     */
    CaseCollectorService.prototype.getComment = function (caseId, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.getComment,
            data: {
                caseId: caseId,
            },
            loading: loading,
        });
    };
    /**
     * 修改记事本内容
     */
    CaseCollectorService.prototype.modifyComment = function (data, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.modifyComment,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询案件状态
     */
    CaseCollectorService.prototype.getCaseStatus = function (caseId, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.getCaseStatus,
            data: {
                caseId: caseId,
            },
            loading: loading,
        });
    };
    /**
     * 更新案件状态
     */
    CaseCollectorService.prototype.updateStatus = function (data, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.updateStatus,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询委托方下批次号
     */
    CaseCollectorService.prototype.getBatchNumber = function (principalId, type, loading) {
        return this.netService.send({
            server: businessService.caseCollectorController.getBatchNumber,
            data: {
                principalId: principalId,
                type: type,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseCollectorService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "findPrincipal", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "findAllInCollectCase", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "findAllCaseByPrincipal", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "findAllRetireCase", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "findAllBatchCase", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "findAllCase", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "getCaseInfoById", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "getCardInfoByIdCard", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "getComment", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "modifyComment", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "getCaseStatus", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "updateStatus", null);
    __decorate([
        Debounce()
    ], CaseCollectorService.prototype, "getBatchNumber", null);
    return CaseCollectorService;
}(Service));
export { CaseCollectorService };
