import { requestType } from "~/config/enum.config";
var SERVICE = "dataimp-service";
var CONTROLLER = "dataImportController";
export default {
    /**
     * 导入案件结果查询
     */
    findAllDataImportResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllDataImportResult",
        type: requestType.Get,
    },
    /**
     * 案件导入
     */
    importCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importCase",
        type: requestType.Post,
    },
    /**
     * 取消导入
     */
    cancelExcelImpCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImport",
        type: requestType.Get,
    },
    /**
     * 获取导入后的案件详情列表
     */
    getImportDataExcelCaseDetailList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 案件导入确认
     */
    confirmCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmCase",
        type: requestType.Get,
    },
    /**
     * 待分配案件多条件查询
     */
    findDistributeCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findDistributeCase",
        type: requestType.Get,
    },
    /**
     * 结清案件查询
     */
    findSettleCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findSettleCasePost",
        type: requestType.Post,
    },
    /**
     * 案件更新导入
     */
    importCaseUpdate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importCaseUpdate",
        type: requestType.Post,
    },
    /**
     * 案件更新导入确认
     */
    confirmUpdateCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmUpdateCase",
        type: requestType.Get,
    },
    /**
     * 默认模板ID返回
     */
    getTemplateId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getTemplateId",
        type: requestType.Get,
    },
    /**
     * 对账单导入
     */
    importBill: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importBill",
        type: requestType.Post,
    },
    /**
     * 对账单取消导入
     */
    cancelImportBill: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImportBill",
        type: requestType.Get,
    },
    /**
     * 对账单详情查询
     */
    findImportBillByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportBillByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 对账单导入确认
     */
    confirmImportBill: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImportBill",
        type: requestType.Get,
    },
    /**
     * 对账单单个撤销
     */
    cancelBillImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelBillImport",
        type: requestType.Get,
    },
    /**
     * 对账单批量撤销
     */
    cancelBatchBillImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelBatchBillImport",
        type: requestType.Get,
    },
    /**
     * 委前调记导入
     */
    importPreRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importPreRecord",
        type: requestType.Post,
    },
    /**
     * 委前调记取消导入
     */
    cancelImportPreRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImportPreRecord",
        type: requestType.Get,
    },
    /**
     * 委前调记详情查询
     */
    findImportPreByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportPreByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 委前调记导入确认
     */
    confirmImportPreRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImportPreRecord",
        type: requestType.Get,
    },
    /**
     * 委前调记导入撤销
     */
    cancelBatchPreImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelBatchPreImport",
        type: requestType.Get,
    },
    /**
     * 工单导入
     */
    importWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importWorkOrder",
        type: requestType.Post,
    },
    /**
     * 警告导入
     */
    importWarning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importWarning",
        type: requestType.Post,
    },
    /**
     * 工单详情查询
     */
    findImportWorkByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportWorkByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 警告详情查询
     */
    findImportWarningByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportWarningByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 工单取消导入
     */
    cancelImportWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImport",
        type: requestType.Get,
    },
    /**
     * 警告取消导入
     */
    cancelImportWarning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImportWarning",
        type: requestType.Get,
    },
    /**
     * 工单导入确认
     */
    confirmImportWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImportWorkOrder",
        type: requestType.Get,
    },
    /**
     * 警告导入确认
     */
    confirmImportWarning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImportWarning",
        type: requestType.Get,
    },
    /**
     * 工单导入撤销
     */
    cancelBatchWorkImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelBatchWorkImport",
        type: requestType.Get,
    },
    /**
     * 警告导入撤销
     */
    cancelBatchWarningImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelBatchWarningImport",
        type: requestType.Get,
    },
    /**
     * 留案导入
     */
    leaveImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "leaveImport",
        type: requestType.Post,
    },
    /**
     * 停调导入
     */
    stopImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "stopImport",
        type: requestType.Post,
    },
    /**
     * 留案导入详情查询
     */
    findImportLeaveByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportLeaveByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 停调导入详情查询
     */
    findImportStopByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportStopByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 留案取消导入
     */
    cancelImportLeave: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImportLeave",
        type: requestType.Get,
    },
    /**
     * 停调取消导入
     */
    cancelImportStop: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImportStop",
        type: requestType.Get,
    },
    /**
     * 留案导入确认
     */
    confirmImportLeave: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImportLeave",
        type: requestType.Get,
    },
    /**
     * 停调导入确认
     */
    confirmImportStop: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImportStop",
        type: requestType.Get,
    },
    /**
     * 留案导入批量撤销
     */
    cancelBatchLeaveImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelBatchLeaveImport",
        type: requestType.Get,
    },
    /**
     * 停调导入批量撤销
     */
    cancelBatchStopImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelBatchStopImport",
        type: requestType.Get,
    },
    /**
     * 下载分案导入模板
     */
    downloadDistributeTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "downloadDistributeTemplate",
        type: requestType.Get,
    },
    /**
     * 分案导入
     */
    importDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importDistribute",
        type: requestType.Get,
    },
    /**
     * 分案导入确认
     */
    confirmImportDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImportDistribute",
        type: requestType.Get,
    },
    /**
     * 分案导入取消
     */
    cancelImportDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImportDistribute",
        type: requestType.Get,
    },
    /**
     * 导入详情
     */
    findImportDistributeByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findImportDistributeByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 分案导入结果查询
     */
    findAllDistributeRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllDistributeRecord",
        type: requestType.Get,
    },
};
