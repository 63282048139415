import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'leaveCaseController';
export default {
    /**
     * 留案案件查询
     */
    queryLeaveCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryLeaveCasesPost',
        type: requestType.Post
    }
};
