import { requestType } from "~/config/enum.config";
var SERVICE = "domain-service";
var CONTROLLER = "assistCollectionCaseController";
export default {
    /**
     * 协调管理查外访案件
     */
    searchAssistCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchAssistCase",
        type: requestType.Get,
    },
    /**
     * 调收管理查询协调案件
     */
    searchAssistCollectionCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchAssistCollectionCasePost",
        type: requestType.Post,
    },
    endAssistCaseCollection: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "endAssistCaseCollection",
        type: requestType.Put,
    },
    /**
     * 撤回协调
     */
    retractAssistCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "retractAssistCase",
        type: requestType.Post,
    },
    /**
     * 查询当前用户协调案件
     */
    searchOwnAssistCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchOwnAssistCase",
        type: requestType.Get,
    },
};
